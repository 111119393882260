


















import { defineComponent } from '@nuxtjs/composition-api';
import { SfSkeleton } from '@storefront-ui/vue';

export default defineComponent({
  name: 'SkeletonRichText',
  components: {
    SfSkeleton,
  },
});
